<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box" id="modal">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle is-5 has-text-primary">
              <span class="icon"><i class="fal fa-recycle"/></span>
              <span>Re-process sheet</span>
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-primary" @click="close">Close</a>
          </div>
        </div>
        <hr class="hr is-light" />
        <div class="content">
          <p>
            Re-processing this sheet will re-create fresh outputs on this sheet.
            If you did not provide a valuation date, this will be updated to
            reflect today's value. Your old sheet will be preserved in your
            history.
          </p>
          <p>Your sheet will be named the following</p>
          <p class="input has-text-info has-text-weight-bold">
            {{ fileName }}
          </p>
          <p>Are you sure you want to continue?</p>
          <div class="buttons is-right">
            <button class="button is-text has-text-danger" @click="close">
              Cancel
            </button>
            <button
              class="button is-soft is-rounded"
              @click="confirm"
              :class="{ 'is-loading': $wait.is('re-process-loading') }"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { confirm } from '../services'
import { mapGetters } from 'vuex'
export default {
  name: 'MultiReProcess',
  computed: {
    ...mapGetters({ token: 'auth/token', fileName: 'modal/config' })
  },
  methods: {
    async confirm() {
      try {
        this.$wait.start('re-process-loading')
        await confirm(this.fileName, this.token)
        this.$router.push({ name: 'multi-dash' }).catch(() => {})
      } catch {
        this.$notify('There was an issue reprocessing your sheet')
      } finally {
        this.close()
        this.$wait.end('re-process-loading')
      }
    },
    close() {
      this.$modal.close()
    }
  }
}
</script>
